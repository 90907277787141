import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  IconButton,
  useMediaQuery,
  Drawer,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import InsertEmoticonSharpIcon from '@mui/icons-material/InsertEmoticonSharp';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TodayIcon from '@mui/icons-material/Today';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import PeopleIcon from '@mui/icons-material/People';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import MenuIcon from '@mui/icons-material/Menu'; // Menu icon for open state
import CloseIcon from '@mui/icons-material/Close'; // Close icon for close state
import PixIcon from '@mui/icons-material/Pix';
import AnimatedFooter from './Dev';
import AdminDashboard from './dash';
import { GridSearchIcon } from '@mui/x-data-grid';

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navItems = [
    { path: '/home', label: 'Dashboard', icon: <DashboardIcon /> },
    { path: '/customers', label: 'Customers', icon: <PeopleIcon /> },
    { path: '/today-invoices', label: "Today's Invoices", icon: <TodayIcon /> },
    // { path: '/yesterday-invoices', label: "Yesterday's Invoices", icon: <FlipToFrontIcon /> },
    { path: '/vendors', label: 'Vendors', icon: <PeopleIcon /> },
    { path: '/inventory', label: 'Inventory', icon: <StorefrontTwoToneIcon /> },
    { path: '/searchInvoice', label: 'Search Invoice', icon: <GridSearchIcon /> },
    { path: '/searchWatak', label: 'Search Watak', icon: <GridSearchIcon /> },


    
  ];

  if (isSmallScreen) {
    navItems.push({ path: '/viewTodaysWataks', label: 'View Wataks', icon: <PixIcon /> });
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="Logo"
              width={isSmallScreen ? 50 : 80}
              style={{ marginRight: '10px', verticalAlign: 'middle' }}
            />
          </Typography>

          {isAuthenticated ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              {isSmallScreen ? (
                <>
                  <IconButton
                    color="inherit"
                    onClick={toggleDrawer(!drawerOpen)}
                  >
                    {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                  <Drawer
                    anchor="left"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    sx={{
                      '.MuiDrawer-paper': {
                        backgroundColor: '#1976d2',
                        fontFamily: "Roboto, Arial, sans-serif",
                        color: 'white',
                        fontSize: "14px",
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure it's above other content
                      },
                    }}
                  >
                    <List sx={{ flexGrow: 1 }}>
                      {navItems.map(({ path, label, icon }) => (
                        <NavLink
                          key={path}
                          to={path}
                          style={({ isActive }) => ({
                            textDecoration: 'none',
                            display: 'block',
                            color: isActive ? '#131842' : 'white',
                            backgroundColor: isActive ? '#F7F9F2' : 'transparent',
                            borderRadius: '4px',
                            marginBottom: '4px',
                            padding: '8px 6px',
                          })}
                        >
                          <ListItem
                            button
                            onClick={toggleDrawer(false)}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: 'inherit',
                            }}
                          >
                            {icon}
                            <ListItemText primary={label} sx={{ paddingLeft: "10px" }} />
                          </ListItem>
                          
                        </NavLink>
                        
                      ))}
                       {/* <div style={{ textAlign: 'center', padding: '18px',fontFamily:"Fantasy", fontSize: '14px', color: '#FFFED3', borderTop: '1px solid #F7F9F2', marginTop: 'auto',marginBottom:"0" }}>
                      Developed by Zubair 
                    </div> */}
                    </List>
                    <AnimatedFooter></AnimatedFooter>
                    
                   
                  </Drawer>
                  
                </>
              ) : (
                navItems.map(({ path, label, icon }) => (
                  <NavLink
                    key={path}
                    to={path}
                    style={({ isActive }) => ({
                      textDecoration: 'none',
                      color: isActive ? '#131842' : 'white',
                      borderBottom: isActive ? '2px solid #131842' : 'none',
                      padding: '0 5px',
                      '&:hover': {
                        borderBottom: isActive ? '2px solid #f39c12' : '2px solid #34495e',
                      },
                    })}
                  >
                    <Button
                      startIcon={icon}
                      sx={{ color: 'inherit' }}
                    >
                      {label}
                    </Button>
                  </NavLink>
                ))
              )}
              <IconButton color="inherit" onClick={logout} aria-label="logout">
                <LogoutIcon />
              </IconButton>
            </Box>
          ) : null}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
