import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const LedgerModal = ({ open, onClose, ledgerData, customerName }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (ledgerData) {
      setLoading(false);
    }
  }, [ledgerData]);

  if (!open) return null;
  if (loading) return <CircularProgress />;

  const { startingBalance, ledgerDetails, endingBalance } = ledgerData;

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Kichloo & Co.</title>
          <style>
            body {
              font-family: 'Consolas', 'Courier New', monospace;
              
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 1rem;
            }
            th, td {
              border: 1px solid #ccc;
              padding: 0.5rem;
              text-align: left;
            }
            th {
              background-color: #f0f0f0;
            }
          </style>
        </head>
        <body>
          <h2>Ledger Details : ${customerName.toUpperCase()}</h2>
          <p><strong>Opening Balance:</strong> ₹${startingBalance.toFixed(2)}</p>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Amount (₹)</th>
                <th>Balance After Entry (₹)</th>
              </tr>
            </thead>
            <tbody>
              ${ledgerDetails.map((entry, index) => `
                <tr>
                  <td>${new Date(entry.date).toLocaleDateString('en-GB')}</td>
                  <td>${entry.description}</td>
                  <td>₹${entry.amount.toFixed(2)}</td>
                  <td>₹${entry.balanceAfterEntry.toFixed(2)}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
          <p><strong>Ending Balance:</strong> ₹${endingBalance.toFixed(2)}</p>
          <script>
            window.focus();
            window.print();
            window.close();
          </script>
        </body>
      </html>
    `);
  };

  const handleSaveAsPDF = () => {
    const doc = new jsPDF();
    
    // Header Section
    doc.setFontSize(24);
    doc.setFont("Helvetica", "bold");
    doc.text("Kichlooo and Co.", doc.internal.pageSize.getWidth() / 2, 20, { align: "center" });
    
    // Subtitle for Ledger Details
    doc.setFontSize(16);
    doc.setFont("Helvetica", "normal");
    doc.text(`Ledger Details for ${customerName.toUpperCase()}`, doc.internal.pageSize.getWidth() / 2, 30, { align: "center" });
    
    // Opening Balance
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Reset to black for other text
    doc.text(`Opening Balance: Rs. ${startingBalance.toFixed(2)}`, 14, 40);
    doc.text('----------------------------------------', 14, 45); // Separator line
  
    // Adjusted Table Column Styles
    const tableColumnStyles = {
      0: { cellWidth: 30, halign: 'center' },
      1: { cellWidth: 70, halign: 'left' }, // Adjusted width
      2: { cellWidth: 30, halign: 'right' },
      3: { cellWidth: 50, halign: 'right' }, // Adjusted width to prevent cutting
    };
  
    const tableData = ledgerDetails.map(entry => [
      new Date(entry.date).toLocaleDateString('en-GB'),
      entry.description,
      `${entry.amount.toFixed(2)}`,
      `${entry.balanceAfterEntry.toFixed(2)}`
    ]);
  
    // AutoTable with Margin Adjustments
    doc.autoTable({
      startY: 50, // Adjusted to account for header and subtitle
      head: [['Date', 'Description', 'Amount', 'Balance After Entry']],
      body: tableData,
      theme: 'grid',
      margin: { top: 10, left: 14, right: 14 }, // Added margins for left and right
      styles: {
        font: 'courier',
        cellPadding: 4,
        fontSize: 10,
        overflow: 'linebreak',
        valign: 'middle',
        halign: 'center', // Center-aligning all cells
      },
      headStyles: {
        fillColor: [22, 160, 133], // Header background color
        textColor: [255, 255, 255], // Header text color
        fontStyle: 'bold',
        halign: 'center',
      },
      columnStyles: tableColumnStyles,
      didDrawPage: (data) => {
        // Add Footer on Each Page
        const pageHeight = doc.internal.pageSize.getHeight();
        
        // Footer with "Software by Mir Zubair"
        doc.setFontSize(10);
        doc.text('Software by Mir Zubair', doc.internal.pageSize.getWidth() / 2, pageHeight - 10, { align: 'center' });
      },
    });
  
    // Ending Balance
    const finalY = doc.autoTable.previous.finalY; // Get the final Y position after the table
    doc.text('----------------------------------------', 14, finalY + 10); // Separator line
    doc.setFontSize(12);
    doc.text(`Ending Balance: Rs. ${endingBalance.toFixed(2)}`, 14, finalY + 15);
  
    // Footer with generated date
    doc.setFontSize(10);
    doc.text('Generated on: ' + new Date().toLocaleString(), 14, finalY + 25); // Date and time of generation
  
    // Save the document
    doc.save(`${customerName}_ledger.pdf`);
  };
  
  

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: '80%',
          margin: 'auto',
          marginTop: '5%',
          padding: 2,
          backgroundColor: 'white',
          boxShadow: 24,
          position: 'relative',
          height: '80vh',
          overflowY: 'auto',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
          Ledger Details for {customerName.toUpperCase()}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>Opening Balance:</strong> ₹{startingBalance.toFixed(2)}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Amount (₹)</TableCell>
                <TableCell align="right">Balance After Entry (₹)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ledgerDetails.map((entry, index) => (
                <TableRow key={index}>
                  <TableCell>{new Date(entry.date).toLocaleDateString('en-GB')}</TableCell>
                  <TableCell>{entry.description}</TableCell>
                  <TableCell align="right" sx={{ color: entry.amount < 0 ? "red" : "green" }}>₹{entry.amount.toFixed(2)}</TableCell>
                  <TableCell align="right">₹{entry.balanceAfterEntry.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          <strong>Ending Balance:</strong> ₹{endingBalance.toFixed(2)}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
          {
            window.innerWidth<=600?(""):(<Button
              variant="contained"
              color="primary"
              onClick={handlePrint}
            >
              Print
            </Button>)
          }
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveAsPDF}
          >
            Save as PDF
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default LedgerModal;
