import React, { useEffect, useRef } from 'react';
import anime from 'animejs';

const AnimatedText = ({ text, color = '#F4CE14' }) => {
  const textRef = useRef(null); // Ref to access the text element

  useEffect(() => {
    if (textRef.current) {
      // Wrap every letter in a span
      textRef.current.innerHTML = textRef.current.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

      // Animate the text
      anime.timeline()
        .add({
          targets: '.ml2 .letter',
          scale: [4, 1],
          opacity: [0, 1],
          translateZ: 0,
          easing: "easeOutExpo",
          duration: 950,
          delay: (el, i) => 70 * i
        })
        .add({
          targets: '.ml2',
          opacity: 1,
          duration: 1000,
          easing: "easeOutExpo",
          delay: 1000,
          complete: () => {
            // Ensure the final styles are applied using requestAnimationFrame
            requestAnimationFrame(() => {
              if (textRef.current) {
                textRef.current.style.opacity = '1';
                textRef.current.querySelectorAll('.letter').forEach(letter => {
                  letter.style.transform = 'scale(1)';
                  letter.style.opacity = '1';
                });
              }
            });
          }
        });
    }
  }, [text]);

  return (
    <>
      {/* Add the keyframes and styles to the document head */}
      <style>
        {`
          .ml2 {
            font-weight: 400;
            font-size: 1.9em;
            opacity: 1; /* Ensuring opacity is set to 1 */
            color: ${color}; /* Apply the color */
          }
          .ml2 .letter {
            display: inline-block;
            line-height: 1em;
            transform: scale(1); /* Ensuring final scale is applied */
            opacity: 1; /* Ensuring final opacity is applied */
          }
        `}
      </style>

      <h1 className="ml2" ref={textRef}>{text}</h1>
    </>
  );
};

export default AnimatedText;
